import React, { FunctionComponent } from "react"
import { BackgroundProps, getBackgroundProps } from "./withBackground"

export interface BackgroundGradientProps {
  gradient: string
}

export const getBackgroundGradientProps = ({
  gradient,
}: BackgroundGradientProps) => {
  const backgroundProps: BackgroundProps = {
    firstLayer: {
      image: gradient,
    },
  }

  return getBackgroundProps(backgroundProps)
}

export const withBackgroundGradient =
  <P extends {}>(
    Component: React.ComponentType<P>
  ): FunctionComponent<P & BackgroundGradientProps> =>
  ({ gradient, ...props }: BackgroundGradientProps) => {
    const backgroundProps = getBackgroundGradientProps({ gradient })

    return <Component {...backgroundProps} {...(props as P)} />
  }
