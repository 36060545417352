export type GradientMode = "linear-gradient" | "radial-gradient"

export interface GradientOptions {
  type: GradientMode
  degrees?: number
  startingColor: string
  endingColor: string
}

export const defaultGradientOptions: GradientOptions = {
  type: "linear-gradient",
  degrees: 0,
  startingColor: "#00AD3C",
  endingColor: "#164E63",
}

export const makeDynamicGradient = (
  options: GradientOptions = defaultGradientOptions
) =>
  `${options.type}(${options.degrees}deg, ${options.startingColor} 0%, ${options.endingColor} 100%)`
