import { FunctionComponent } from "react"
import { BoxWithBackgroundGradient } from "../components/backgrounds/BoxWithBackgroundGradient"
import { DarkThemeProvider } from "../theme"
import { dynamicColorGradient } from "../theme/gradients/dynamicColorGradient"
import { BaseLayout } from "./base"

export interface BaseBackgroundLayoutProps {
  login?: boolean
}

export const BaseBackgroundLayout: FunctionComponent<BaseBackgroundLayoutProps> =
  ({ login = true, children }) => (
    <BaseLayout>
      <BoxWithBackgroundGradient
        gradient={dynamicColorGradient({
          degrees: 45,
          type: "linear-gradient",
          startingColor: "#F2F7FF",
          endingColor: "#F2F7FF",
        })}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        style={{ height: "100vh" }}
      >
        <DarkThemeProvider>{children}</DarkThemeProvider>
      </BoxWithBackgroundGradient>
    </BaseLayout>
  )
